@import "_variables";
@import "_body";
/* @import "_header";
@import "_card";
@import "_sceneSelector";
@import "_topicEditor";
@import "_welcome";
@import "_forms"; 
@import "_nav";
@import "_alert";
@import "_tables";
@import "_grid";
*/
@import "cameraPositions";
@import "_source-editor";
@import "_teleprompter";
@import "_pages";

@import "_colors";

@import "_account";
@import "_showlist";

@import "_switch";

.showprogress {
  padding: 10px;
  margin: 0 2px 0 0;
  background-color: green;
  display: inline-block;

  &.live {
    background-color: red;
  }
}

.main {
  padding-bottom: 60px;

  .prompter-on {
    display: none;
  }

  .episode-number {
    font-size: 1em;
  }

  .show-title {
    font-size: 1.75em;
    @media (max-width: 640px) {
      font-size: 1.3em;
    }
  }

  .show-url {
    font-size: 0.8em;
    @media (max-width: 640px) {
      font-size: 0.8em;
    }
  }

  .ticker-options {
    font-size: 0.6em;
    text-transform: uppercase;
    margin-top: 5px;
  }

  .show-date {
    font-size: 0.8em;
    text-transform: uppercase;
    @media (max-width: 640px) {
      font-size: 0.8em;
    }
  }

  &.full-width {
    display: block;
    .col1 {
      flex: 100;
      padding: 0 1px 0px 5px;
    }

    .section-wrap {
      .col {
        width: 100% !important;
        flex-basis: 100%;

        &.first {
          border-right: none;
          padding-right: auto;
        }

        .time-wrap {
          width: 100%;
          font-size: 1.4em;
          .sideby {
            margin-right: 10px;
          }
        }
      }
    }

    .show-controls {
      display: flex;
      gap: 2px;
      button {
        flex: 1;
      }
    }
  }

  .col1 {
    flex: 100;
    padding: 0 10px 10px 10px;
  }
}

.white-bg {
  background-color: white;
}

.center-wrap {
  margin: auto;
  padding: 20px;
  text-align: center;
  img {
    width: 100%;
    height: auto;
  }
}

.tiny {
  font-size: 0.7em;
}

.ticker {
  width: auto;
  overflow: hidden;
  margin: 10px auto 10px auto;
}

.scrolling-text-container {
  width: 100%;
  position: fixed;
  bottom: 0px;
  background-color: black;
  font-weight: 700;
  text-transform: uppercase;
}

.marquee {
  font-size: 0.8em;
}

.section-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: $secondary-background-color;
  padding: 10px;

  &.centered {
    justify-content: center;
  }

  .col {
    flex: 0 0 calc(50% - 20px);

    &.first {
      border-right: solid 1px grey;
      max-width: 500px;
      padding-right: 10px;

      @media (max-width: 640px) {
        border-right: none;
        border-bottom: solid 1px grey;
        padding-bottom: 10px;
      }
    }

    @media (max-width: 640px) {
      width: 100%;
      flex: initial;
    }
  }
}

.app-controls {
  padding: 10px 0px;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0px;
  background-color: $primary-background-color;
  border-bottom: solid 1px #262627;
  z-index: 200;

  &.studio {
    padding: 0px 0px 10px;
    div {
      display: flex;

      button {
        flex: 1;
        margin-right: 0;
      }
    }

    &.live {
      background-color: $primary-background-color;
      border-bottom: initial;
    }
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div {
    display: inline-block;
    justify-content: space-around;
    @media (max-width: 640px) {
      gap: 3px;
      width: 100%; /* Make the container of the two buttons full width */
      display: flex;
      justify-content: space-around;

      &:not(:first-of-type) {
        margin-top: 3px;
      }
    }
  }

  &.live {
    background-color: #ff002a;
    border-bottom: solid 2px red;
  }

  /* button {
    font-size: 0.6em;
    margin-right: 3px;
    background-color: #494a4b;
    cursor: pointer;

    &:hover {
      background-color: #515152;
    }

    &.active {
      background-color: red;
    }

    @media (max-width: 640px) {
      width: 100%;
      margin-right: initial;
    }
  } */
}

a {
  color: white;
  text-decoration: underline;
}

hr {
  border-color: $primary-border-color;
  margin: 10px auto;
}

.editableInput {
  border: none;
  background-color: rgba(85, 85, 87, 0.431);
  padding: 5px;
  color: white;
  width: auto;
  border: none;
  font-size: 1em;
  margin-bottom: 5px;
  width: 240px;
  font-family: "Anton", sans-serif;
  font-family: "Roboto Condensed", sans-serif;

  &.show-title {
    text-transform: none !important;
  }
}

select {
  padding: 5px 10px;
  background-color: #2a343f;
  color: white;
  border-color: #404245;
}

.show-controls {
  background-color: $primary-background-color;
  padding: 10px 0;

  @media (max-width: 640px) {
    text-align: center;
    button {
      width: 32.3333%;
    }
  }
}

.status-wrap {
  padding: 20px;
  display: flex;
  align-items: center;
  z-index: 150;
  background-color: $primary-background-color;
  border-bottom: solid 1px #2f3337;
  &.stick {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Adjust the distance from the top as needed */
  }
}

.time-wrap {
  font-size: 2em;
  @media (max-width: 640px) {
    text-align: center;
    font-size: 1.7em;
  }

  .sideby {
    display: inline-block;
    margin-right: 20px;
  }

  .time-offset {
    font-size: 70%;
  }

  .small {
    font-size: 0.6em;
    display: block;
  }
  .col {
    display: inline-block;
    margin-right: 20px; /* Optional: Add spacing between the columns */
    vertical-align: top; /* Optional: Align the columns at the top */
  }
}

.status-flag {
  display: flex;
  align-items: center;
  margin-right: 10px;

  .circle {
    width: 30px;
    height: 30px;
    background-color: #5b5b5b;
    border-radius: 50%;
    border: solid 1px #444644;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.95em;
  }

  label {
    display: inline-block;
    background-color: #5b5b5b;
    border: solid 1px #444644;
    padding: 5px 10px 5px 20px;
    margin-left: -10px;
    color: white;
    z-index: 1;
  }

  &.active {
    .circle {
      background-color: red;
    }
    label {
      background-color: red;
    }
  }
}

.btn {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

/* a.button {
  display: inline-block;
  text-decoration: none;
}
a.button,
button {
  color: white;
  background-color: #494a4b;
  border: none;
  cursor: pointer;
  padding: 7px 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.6em;
  border: solid 1px $primary-border-color;
  transition: background-color 0.3s ease;

  &.all-shows {
    @media (max-width: 640px) {
      width: 100%; 
      flex: initial;
      margin-bottom: 3px;
    }
  }

  &.segment-btn {
    &:hover {
      background-color: green;
    }
  }

  &.disabled {
    background-color: rgb(49, 49, 49);
    color: rgb(99, 99, 99);
  }

  &.end {
    background-color: red;

    &:hover {
      background-color: rgb(179, 5, 5);
    }
  }

  &.editable {
    background-color: red;
  }

  &:hover {
    background-color: #373636;
  }

  &.green {
    background-color: $green;
    color: black;
    &:hover {
      background-color: #59952d;
    }
  }

  &.grey {
    color: rgb(99, 99, 99);
    background-color: rgb(49, 49, 49);
  }

  &.red {
    background-color: red;
    &:hover {
      background-color: rgb(160, 11, 11);
    }
  }

  &.blue {
    background-color: #1765a5;

    &:hover {
      background-color: #0f4877;
    }
  }

  &.darkred {
    background-color: #960b0b;
    &:hover {
      background-color: #691d1d;
    }
  }

  &.darkgreen {
    background-color: #0b5511;
    &:hover {
      background-color: #09370d;
    }
  }

  &.orange {
    background-color: #c55d07;
  }

  &.yellow {
    background-color: #c3b50d;
    color: black;
    &:hover {
      background-color: #a09308;
    }
  }

  &.save {
    background-color: $green;
  }
} */

.action-group {
  text-align: right;
}

.modal-overlay,
.stats-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    #575f67b5,
    #091724
  ); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9700;

  &.custom-alert-modal {
    z-index: 9900;
  }
}

.show-stats {
  background-color: #1c2f43;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 90%;
  min-height: 400px;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  border: solid 1px #303a45;

  @media (max-width: 1024px) {
    min-width: initial;
    width: 96%;
    max-height: 90%;
    margin: auto;
  }

  .title {
    display: block;
    font-size: 1.2em;
  }

  .close-button {
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.caps {
  text-transform: uppercase;
}

@keyframes colorAnimation {
  0% {
    background-color: #092418; /* Initial color */
  }
  100% {
    background-color: #c10d0d; /* Target color */
  }
}

@keyframes colorAnimation2 {
  0% {
    border-color: #0d3121; /* Initial color */
  }
  100% {
    border-color: #ba0404; /* Target color */
  }
}

.mobile-hide {
  @media (max-width: 460px) {
    display: none;
  }
}

.row {
  /* display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  gap: 20px; */

  .col {
    flex: 1;

    @media (max-width: 640px) {
      width: 100%; /* Set the card to 100% width under 480px */
      flex: initial;
    }
  }

  .seperator-label {
    width: 100px;
  }
}
