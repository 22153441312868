.show-list-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(#575f67b5, #091724);

  display: none;
  z-index: 9000;

  &.on {
    display: initial;
  }
}

.show-list {
  position: fixed;
  top: 0px;
  left: 0;
  width: 80%;
  height: 100.2%;
  background: linear-gradient(#091724, #091724);
  transition: transform 0.15s ease;
  transform: translateX(-102%);
  z-index: 10;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.25);
  border-left: solid 1px #31516c;
  border-bottom: solid 1px #31516c;
  border-top: solid 1px #31516c;
  border-radius: 0px 8px 8px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  z-index: 9999;

  .show-list-items {
    @media (min-width: 640px) {
      padding: 10px 0px;
    }
  }

  .nav-header {
    padding: 20px 20px 20px 10px;
    color: white;
    text-align: left;
    border-bottom: 1px solid #4a5863;
    background-color: #2c3842;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    span {
      font-size: 0.8em;
      font-weight: 400;
    }
  }

  @media (min-width: 640px) {
    max-width: 900px;
  }

  &.on {
    transform: translateX(0rem);
  }

  .show-list-options {
    font-size: 0.8em;
    margin: 20px 10px;

    @media (min-width: 640px) {
      margin: 20px 10px 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: inline;
        margin-right: 10px;
        cursor: pointer;
        &.selected {
          color: #e8fba1;
        }

        &:not(:last-child)::after {
          content: " | ";
          color: white;
          margin-left: 5px;
        }
      }
    }
  }

  .close {
    position: absolute;
    top: 12px;
    right: 20px;
    cursor: pointer;
    font-size: 1.45rem;
    float: right;
  }

  .item {
    border-bottom: solid 1px #3f4747;
    display: block;
    width: 100%;
    font-size: 0.9em;
    font-weight: 300;
    transition: background-color 0.3s ease-in-out;
    color: #dbdada;
    cursor: pointer;

    @media (min-width: 640px) {
      display: inline-block;
      width: calc(50% - 4px);
      margin: 2px 2px 0 2px;
    }

    .small {
      font-weight: 500;
    }
    &.selected {
      color: #fbc7a1;
      background-color: #1d3246;
      font-weight: 600;
    }

    &:hover {
      color: white;
      background-color: #1d3246;

      hr {
        border-color: #13212f;
      }
    }

    .item-inner {
      padding: 10px;
      overflow-x: clip;

      svg {
        margin-right: 5px;
      }
    }
  }

  .show-browser-footer {
    margin-top: 20px;
    font-size: 0.8em;
    padding: 15px;
  }

  .no-wrap {
    white-space: nowrap;
  }
}
