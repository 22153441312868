.redlatex {
  font-family: "Vidaloka", serif;
}

.rundown {
  &.redlatex {
    width: 450px;
    font-size: 18px;
    position: absolute;
    top: 10%;
    left: -130%;
    padding: 10px;
    border: none;
    border-radius: 8px;
    transition: all 0.6s;

    &.on {
      left: 30px;
    }

    ul {
      li {
        margin: 5px;
        list-style: none;
        padding: 10px 15px;
        color: #3b3738;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 80%;
        box-shadow: inset 1px 2px 5px 2px #000000;
        background-color: #fff0f3;
        transition: all 0.6s;
        border-radius: 70px;
        width: auto;
        margin-bottom: 10px;
        filter: drop-shadow(2px 4px 3px #000000c7);
        border: solid 5px #d60f23e7;
        &.selected {
          max-width: 90%;
          background: linear-gradient(
            -91deg,
            #fb1a2c,
            #fd0011,
            #d60f23,
            #fd0011,
            #b85f65
          );
          animation: redlatex-gradient 5s ease infinite;
          background-size: 200% 200%;
          border: solid 5px #fff0f3;

          color: white;
          margin: auto auto auto 20px;
        }
      }
    }
  }
}

.scrolling-text-container {
  &.redlatex {
    width: 100%;
    position: fixed;
    bottom: 0px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;

    .marquee {
      font-size: 1.5em;
    }

    .ticker {
      font-size: 18px;
      border-top: solid 5px #fff0f3;
      color: white;
      width: auto;
      padding: 10px 10px;
      overflow: hidden;
      margin: 10px auto 0px auto;
      animation: redlatex-gradient 10s ease infinite;
      background-size: 400% 400%;
      background: linear-gradient(
        -91deg,
        #fb1a2c,
        #fd0011,
        #d60f23,
        #fd0011,
        #b85f65
      );
      box-shadow: inset 1px 2px 5px 2px #000000;
    }
  }
}

.topic-container {
  &.redlatex {
    display: flex;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: center;

    &.on {
      bottom: 60px;
    }

    .topic-source {
      background: linear-gradient(
        -91deg,
        #fb1a2c,
        #fd0011,
        #d60f23,
        #fd0011,
        #b85f65
      );
      box-shadow: inset 1px 2px 5px 2px #000000, 1px 2px 5px 2px #000000;
      animation: redlatex-gradient 10s ease infinite;
      color: white;
      text-shadow: 2px 2px 4px #000000;
      background-size: 200% 200%;
      padding: 20px;
      font-size: 36px;
      font-weight: 800;
      max-width: 60%;
      width: 60%;
      border: solid 11px #fff0f3;

      margin-bottom: 20px;
      border-radius: 70px;

      /* Flexbox properties for vertical alignment */
      display: flex;
      justify-content: center; /* Horizontally center content */
      align-items: center; /* Vertically center content */

      span {
        max-width: 80%;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

@keyframes redlatex-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
