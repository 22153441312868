.flowerpower {
  font-family: "Vidaloka", serif;
}

.rundown {
  &.flowerpower {
    width: 350px;
    font-size: 20px;
    position: absolute;
    top: 10%;
    left: -130%;
    padding: 10px;
    border: none;
    border: 20px solid;
    border-image: url("./img/flower-power-border.png") 30 round;
    border-image-width: 30px;
    transition: all 0.6s;

    &.studio {
      position: absolute;
      font-size: 0.8vw;
      width: 13vw;

      ul {
        li {
          width: 100%;
          padding: 0.5vw;
          margin: 0.5vw;
        }
      }

      &.on {
        left: 15px;
      }

      &.emdeded {
        font-size: 1.4vw;
        width: 19vw;
      }
    }

    &.on {
      left: 30px;
    }

    ul {
      li {
        margin: 5px;
        list-style: none;
        padding: 15px 15px;
        color: black;
        font-weight: 700;
        text-transform: uppercase;
        box-shadow: inset 1px 2px 5px 2px #000000;
        background-color: yellow;
        transition: all 0.6s;
        border-radius: 8px;
        width: auto;
        filter: drop-shadow(2px 4px 3px #000000c7);

        &.selected {
          background: linear-gradient(
            -45deg,
            #fff700,
            #fd00c6,
            #f49be1,
            #fd00c6,
            #fff700
          );
          animation: redlatex-gradient 5s ease infinite;
          background-size: 200% 200%;

          color: black;
        }
      }
    }
  }
}

.scrolling-text-container {
  &.flowerpower {
    width: 100%;
    position: fixed;
    bottom: 0px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;

    .marquee {
      font-size: 1.5em;
    }

    .ticker {
      font-size: 18px;
      color: black;
      width: auto;
      padding: 10px 10px;
      overflow: hidden;
      margin: 10px auto 0px auto;
      animation: redlatex-gradient 10s ease infinite;
      background-color: yellow;
      box-shadow: inset 1px 2px 5px 2px #000000;
    }
  }
}

.topic-container {
  &.flowerpower {
    display: flex;
    position: fixed;
    bottom: 47px;
    left: -100%;
    width: 100%;
    transition: all 0.5s ease-in-out;
    justify-content: center;

    &.on {
      left: 0;
    }

    .topic-source {
      color: black;
      text-shadow: 2px 2px 4px #000000;
      background-size: 200% 200%;
      padding: 20px;
      font-size: 36px;
      font-weight: 800;
      max-width: 60%;
      width: 60%;
      border: 20px solid;
      border-image: url("./img/flower-power-border.png") 30 round;
      border-image-width: 30px;

      margin-bottom: 20px;

      /* Flexbox properties for vertical alignment */
      display: flex;
      justify-content: center; /* Horizontally center content */
      align-items: center; /* Vertically center content */

      span {
        width: 100%;
        box-shadow: inset 1px 2px 5px 2px #000000;
        background-color: yellow;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

@keyframes redlatex-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
