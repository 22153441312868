.rundown {
  &.transparentwhite {
    width: 400px;
    font-size: 30px;
    position: absolute;
    top: 5%;
    left: -150%;
    border-left: none;
    transition: all 0.6s;

    @media (min-width: 3840px) {
      font-size: 4rem;
      width: 910px;
    }

    &.on {
      left: 30px;
    }

    ul {
      li {
        margin: 10px;
        list-style: none;
        padding: 10px 10px;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        transition: all 0.6s;
        width: 375px;
        &.selected {
          background-color: transparent;
          color: white;
          margin-left: 30px;

          @media (min-width: 3840px) {
            border: none;
            width: 100%;
          }
        }

        @media (min-width: 3840px) {
          border: none;
          width: 90%;
          padding: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 640px) {
    display: none;
  }
}

.scrolling-text-container {
  &.transparentwhite {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-color: transparent;
    font-weight: 700;
    text-transform: uppercase;
    border-top: none;
    @media (min-width: 3840px) {
      border-top: solid 10px none;
    }

    .ticker {
      width: auto;
      overflow: hidden;
      margin: 10px auto 10px auto;
    }

    .marquee {
      font-size: 1.5em;

      @media (min-width: 3840px) {
        font-size: 4.3rem;
      }
    }
  }
}

.marquee {
  @media (max-width: 400px) {
    font-size: 20px !important;
  }
}

.topic-container {
  &.transparentwhite {
    display: flex;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: center;

    &.on {
      bottom: 56px;

      @media (min-width: 3840px) {
        bottom: 7%;
      }
    }

    .topic-source {
      background: transparent;
      text-shadow: 3px 8px 19px #000000;
      color: white;
      text-shadow: none;
      padding: 20px;
      font-size: 40px;
      font-weight: 800;
      max-width: 900px;
      width: 900px;
      border: none;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        max-width: 80%;
        text-align: center;
        text-transform: uppercase;
      }

      @media (min-width: 3840px) {
        box-shadow: none;
        text-shadow: 3px 8px 19px #000000;
        border: solid 1.5rem white;
        padding: 2.5rem;
        font-size: 6rem;
        width: 2800px;
        max-width: 2800px;
      }
    }
  }

  .topic-source {
    @media (max-width: 640px) {
      font-size: 20px !important;
    }
  }
}
