.rundown {
  &.topdown {
    width: 100%;
    font-size: 30px;
    position: fixed;
    top: -100%;
    left: 30px;
    transition: all 0.6s;

    &.on {
      top: 50px;
    }

    ul {
      li {
        font-size: 24px;
        display: inline-block;
        margin: 10px 3px;
        list-style: none;
        padding: 10px 20px;
        color: black;
        font-weight: 700;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.81);
        box-shadow: inset 1px 2px 5px 2px #000000, 1px 2px 5px 2px #000000;
        border: solid 3px white;
        transition: all 0.6s;
        width: autp;
        &.selected {
          background-color: rgba(255, 0, 0, 0.911);
          color: white;
        }
      }
    }
  }
}

.scrolling-text-container {
  &.topdown {
    width: 100%;
    position: fixed;
    top: 0px;
    background-color: black;
    font-weight: 700;
    text-transform: uppercase;
    height: 54px;

    .ticker {
      width: auto;
      overflow: hidden;
      margin: 10px auto 10px auto;
    }

    .marquee {
      font-size: 1.5em;
    }
  }
}

.topic-container {
  &.topdown {
    display: flex;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: center;

    &.on {
      bottom: 30px;
    }

    .topic-source {
      border: solid 3px white;
      box-shadow: inset 1px 2px 5px 2px #000000, 1px 2px 5px 2px #000000;
      background-color: rgba(255, 255, 255, 0.81);
      color: black;
      padding: 20px;
      font-size: 40px;
      font-weight: 800;
      max-width: 900px;
      width: 900px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        max-width: 80%;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
