.rundown {
  &.sportyblue {
    width: 30%;
    font-size: 32px;
    position: fixed;
    bottom: 52px;
    right: -150%;
    transition: all 0.6s;

    &.studio {
      position: absolute;
      font-size: 0.8vw;
      width: 13vw;
      bottom: 40px;
      z-index: 10;
      ul {
        li {
          padding: 1vw 0.5vw;
        }
      }
    }

    &.on {
      right: 0px;
    }

    ul {
      li {
        list-style: none;
        padding: 20px 10px;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: 2px 2px 4px #000000;
        background-color: #1d374eeb;
        transition: all 0.6s;
        width: 100%;
        border-bottom: solid 2px #091118;
        &.selected {
          background-color: #009fd9ee;
          color: white;
        }
      }
    }
  }
}

.scrolling-text-container {
  &.sportyblue {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-color: #012b5fea;
    font-weight: 700;
    text-transform: uppercase;

    &.studio {
      position: absolute;

      .marquee {
        font-size: 1.4vh;
      }
    }

    .ticker {
      width: auto;
      overflow: hidden;
      margin: 10px auto 10px auto;
    }

    .marquee {
      font-size: 1.5em;
      text-shadow: 2px 2px 4px #000000;
    }
  }
}

.topic-container {
  &.sportyblue {
    position: fixed;
    bottom: 52px;
    left: -100%;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: left;
    width: 100%;
    transition: all 0.6s;

    &.studio {
      position: absolute;
      width: 100%;
      bottom: 40px;

      .topic-source {
        font-size: 2.5vh;
        padding: 1vh 1vh;
      }
    }

    &.on {
      left: 0px;
    }

    .topic-source {
      box-shadow: inset -2px 0px 9px -2px #000000;
      background: linear-gradient(90deg, #026ba7ee, #1687c9f1, #026aa7);
      color: white;
      text-shadow: 2px 2px 4px #000000;
      padding: 30px 20px;
      font-size: 48px;
      font-weight: 800;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      justify-content: left;
      align-items: left;

      span {
        max-width: 80%;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }
}
