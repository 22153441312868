* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $primary-background-color;
  color: white;

  min-width: 230px;

  /* Set the width of the scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #666 transparent; /* Scrollbar color */

  /* Style the thumb (the draggable part of the scrollbar) */
  &::-webkit-scrollbar-thumb {
    background-color: #666;
  }

  /* Style the track (the non-draggable part of the scrollbar) */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(80, 80, 80) #0d0b0b;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #0d0b0b;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(80, 80, 80);
  border-radius: 20px;
  border: 3px solid #0d0b0b;
}

$item-count: 5;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%},
    #{$i * 25%} {
      transform: translateY((-100% / $item-count) * $i);
    }
  }
}
