.source-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .source-button {
    padding: 40px;
    border: solid 1px $primary-border-color;
    background-color: $secondary-background-color;
    display: flex;
    flex: 0 0 calc(25% - 20px);
    justify-content: center;
    text-align: center;

    @media (max-width: 640px) {
      width: 100%; /* Set the card to 100% width under 480px */
      flex: initial;
    }

    a {
      display: block;
      text-decoration: none;
    }
  }
}

.frame-container {
  aspect-ratio: 16 / 9;
  height: 40%;
  width: auto;
  position: relative;
}

.share-window,
.source-editor {
  padding: 30px;
  background-color: #1c2f43;
  width: 90%;
  max-width: 500px;
  margin: 140px auto auto;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
  border: solid 1px #303a45;

  .small {
    font-size: 0.8em;
  }

  input {
    padding: 5px 20px;
    width: 100%;
    border: solid 1px;
    border-radius: 4px;
    margin: 15px 0;
    background-color: #394e63;
    color: white;
    font-size: 0.8em;

    &.number-pick {
      width: 75px;
      margin: 0 0 10px 10px;
    }

    &.password {
      width: 200px;
      margin-right: 20px;
    }

    &[type="checkbox"] {
      display: inline-block;
      width: auto;
      margin-left: 10px;
    }
  }

  .qr-wrap {
    width: 100%;
    text-align: center;

    img {
      display: block;
      display: block;
      margin: 20px auto;
    }
  }
}

.source-editor {
  z-index: 100;
  position: relative;
  background-color: #1c2f43eb;
  margin: 10% auto auto;
  @media (max-width: 640px) {
    margin: 5% auto auto;
  }
}

.share-window {
  position: fixed;
  margin: auto auto auto;
}
