@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .altcard div.card:nth-child(even) {
    @apply bg-cardalt;
  }

  .altcard div.card:hover:nth-child(even) {
    @apply bg-sshover;
  }

  .grid.full-width {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .flex.full-width {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid.reorder {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .grid.reorder .sorthide {
    display: none;
  }
}

@keyframes overtimefade {
  0% {
    background: #03090f;
  } /* bg-orange-500 */
  50% {
    background: #de010198;
  } /* bg-blue-500 */
  100% {
    background: #03090f;
  } /* bg-orange-500 */
}

@keyframes warningfade {
  0% {
    background: #03090f;
  } /* bg-orange-500 */
  50% {
    background: #e0930585;
  } /* bg-blue-500 */
  100% {
    background: #03090f;
  } /* bg-orange-500 */
}

.overtimeCard {
  animation: overtimefade 2.5s infinite;
}

.warningCard {
  animation: warningfade 2.5s infinite;
}

.scrolling-words-container {
  align-items: center;
}

.scrolling-words-box {
  /* height: 3.5rem; */
  margin: auto;
  overflow: hidden;

  @media (max-width: 640px) {
    /* height: 2.8rem; */
  }

  ul {
    margin: 0;
    padding: 0;
    animation: scrollUp 10s infinite;
    li {
      align-items: flex-end;
      justify-content: flex-end;
      list-style: none;

      &.orange {
        color: orange;
      }

      &.white {
        color: white;
      }

      &.grey {
        color: grey;
      }
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
      &.blue {
        color: blue;
      }
    }
  }
}

/*
STREAMSLICER
*/

.crop::after {
  background-color: #151313;
}
.crop.on {
  display: block;
}
.crop.tall {
  aspect-ratio: 1/1.91;
}
.crop.square_45 {
  aspect-ratio: 4/5;
}
.handle {
  width: 10px;
  height: 10px;
  background-color: grey;
}
.handle.tl {
  position: absolute;
  top: -4px;
  left: -4px;
}
.handle.tr {
  position: absolute;
  top: -4px;
  right: -4px;
}
.handle.br {
  position: absolute;
  bottom: -4px;
  right: -4px;
}
.handle.bl {
  position: absolute;
  bottom: -4px;
  left: -4px;
}

/* .video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 12px;
  padding: 10px;
} */

.video-box {
  background-color: #151313;
  border: solid 1px #262525;
  padding: 0;
  margin-bottom: 1rem;
  border-radius: 5px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  transition: border 0.3s ease-in-out;
  position: relative;
}
.video-box .working {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.88);
  z-index: 1;
}
.video-box .working progress {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  position: absolute;
  width: 80%;
  z-index: 2;
  height: 10px;
}
.video-box .working .progress-title {
  font-weight: 700;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  position: absolute;
  width: 80%;
  z-index: 2;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.8rem;
}
.video-box a {
  color: #ccc;
  text-decoration: none;
  cursor: default;
}
.video-box a:hover {
  color: white;
}
.video-box:hover {
  box-shadow: 0px 0px 12px 0px rgba(207, 6, 255, 0.5);
  border: solid 1px rgb(41, 147, 234);
}
.video-box:hover .caption {
  overflow: auto;
  color: white;
  transition: color 0.3s ease-in-out;
}
.video-box:hover .img-container img.clips {
  filter: none;
}
.video-box .img-container {
  width: 100%;
  position: relative;
}
.video-box .img-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: default;
}
.video-box .img-container img.clips {
  width: 100%;
  cursor: default;
  filter: brightness(0.5);
  transition: filter 0.3s ease-in-out;
}
.video-box .img-container .controls {
  position: absolute;
  top: 30%;
  left: 45%;
  font-size: 4rem;
  display: none;
  pointer-events: none;
  color: rgb(255, 255, 255);
}
.video-box .img-container .more-options {
  position: absolute;
  top: 10px;
  right: 20px;
}
.video-box .img-container i {
  text-shadow: 0px 0px 6px rgba(22, 22, 22, 0.8);
}
.video-box .img-container:hover .controls {
  display: block;
}
.video-box .img-container .clip-time {
  left: 10px;
  top: 10px;
  font-weight: 900;
  position: absolute;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); */
  background-color: rgba(42, 42, 41, 0.7803921569);
  padding: 4px 6px;
  border-radius: 8px;
  font-size: 0.8rem;
}
.video-box .img-container .sentiment-val {
  right: 10px;
  top: 10px;
  font-weight: 900;
  position: absolute;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); */
  background-color: rgba(42, 42, 41, 0.7803921569);
  padding: 4px 6px;
  border-radius: 8px;
  font-size: 0.8rem;
}
.video-box .img-container .sentiment-val.high {
  background-color: green;
}
.video-box .img-container .sentiment-val.low {
  background-color: red;
}
.video-box .img-container .sentiment-val.neutral {
  background-color: grey;
}
.video-box .details {
  padding: 1rem;
  height: 185px;
}
.video-box .details a {
  color: white;
  text-decoration: none;
}
.video-box .keyword {
  padding: 3px 6px;
  border-radius: 5px;
  margin: 5px 5px 0 0;
  background-color: #6c5c7c;
  font-size: 0.7rem;
  display: inline-block;
}
.video-box .transcribe-btn {
  color: white;
  cursor: default;
  border: solid 1px black;
  background-color: green;
  padding: 0.3rem 0.5rem;
  border-radius: 8px;
  display: inline-block;
  margin-top: 5px;
  transition: background-color 0.3s ease-in-out;
  font-weight: 100;
}
.video-box .transcribe-btn:hover {
  background-color: rgb(4, 70, 4);
}
.video-box .transcript-title {
  display: block;
  margin-bottom: 0.4rem;
}
.video-box .transcript {
  padding-bottom: 2rem;
}
.video-box .title-input {
  border: none;
  background-color: transparent;
  display: flow-root;
  color: white;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  height: 50px;
}
.video-box .title-input:focus {
  padding: 0.5rem;
}
.video-box .caption {
  padding: 0 1rem;
  overflow: none;
  height: 180px;
  font-size: 0.85rem;
  color: #b9b9b9;
}
.video-box .caption.tall {
  height: 300px;
}
.video-box .caption .moment-title {
  font-size: 1rem;
  color: #fff;
}

.action-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.action-wrapper .button {
  flex: 1 0 0;
  text-align: center;
  padding: 0.6rem;
  text-transform: uppercase;
  background-color: rgb(41, 147, 234);
  border-top: solid 1px #262525;
  cursor: default;
  font-size: 0.8rem;
  transition: background-color 0.3s ease-in-out;
  font-weight: 100;
  color: #ffffff;
  white-space: nowrap;
}
.action-wrapper .button .green {
  background-color: rgb(59, 96, 59);
}
.action-wrapper .button:hover {
  background-color: rgb(31, 104, 164);
}
.action-wrapper .button:first-child {
  border-right: 1px solid #262525;
}
.action-wrapper .button:nth-child(3) {
  border-left: solid 1px #262525;
}
.action-wrapper .button.disabled {
  background-color: #282828 !important;
  color: rgb(84, 83, 83);
  cursor: default !important;
}
.action-wrapper .button i {
  margin-right: 10px;
}
.action-wrapper .button a {
  text-decoration: none;
}

/*
.video-box .video-box .video-box .img-container {
  width: 100%;
  position: relative;
}
.video-box .img-container .controls {
  position: absolute;
  top: 35%;
  left: 40%;
  font-size: 3rem;
  display: none;
  pointer-events: none;
  color: rgb(120 43 43);
}

.video-box .img-container .controls i {
  text-shadow: 0px 0px 6px rgb(247 242 245 / 80%);
}
.video-box .img-container:hover .controls {
  display: block;
}

.video-box .img-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: default;
}

.video-box .details {
  padding: 1rem;
  height: 185px;
}

.video-box .details a {
  color: white;
  text-decoration: none;
}
*/
.editor-wrap {
}
.editor-wrap.show {
  display: flex;
}

.close-btn {
  position: absolute;
  top: 3%;
  right: 5%;
  font-size: 2rem;
  z-index: 9999;
}
.close-btn.show {
  display: block;
}

.editor {
  background-color: #151313;
  /* border: solid 1px #0c0c0c; */
  -webkit-box-shadow: 0px 5px 4px -1px rgba(0, 0, 0, 0.53);
  box-shadow: 0px 5px 4px -1px rgba(0, 0, 0, 0.53);
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  max-width: 800px;
  min-width: 600px;
  max-height: 675px;
  z-index: 10;
}
.editor .video {
  position: relative;
  justify-content: center;
  display: flex;
}
.editor .video #video-loader img {
  object-fit: cover;
  max-height: 450px;
}
.editor .video video {
  width: auto;
  height: auto;
  max-height: 450px;
}
.editor .video video .editor-head {
  background-color: #151313;
  padding: 1rem;
}
.editor .video .crop-overlay {
  position: absolute;
  top: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7960784314);
  height: 100%;
  display: none;
}
.editor .video .crop-overlay.on {
  display: initial;
}
.editor .video .crop-overlay.left {
  left: 0;
}
.editor .video .crop-overlay.right {
  right: 0;
}
.editor .video .crop {
  position: absolute;
  border: dashed 4px red;
  top: 0;
  left: 260px;
  height: 100%;
  display: none;
}

.editor .player-controls {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.editor .player-controls span {
  padding: 0.5em 0.7em 0.3em;
  border-radius: 8px;
  border: solid 1px transparent;
  margin-left: 5px;
}
.editor .player-controls span:hover,
.editor .player-controls span.on {
  border: solid 1px #262525;
  background-color: #151313;
  background-color: #181818;
}
.editor .player-controls span label {
  margin-left: 10px;
  font-size: 0.7rem;
}
.editor #clip-len {
  font-size: small;
  font-weight: 100;
  color: #c3c3c3;
  margin-top: 0.5rem;
}
.editor.show {
  display: block;
}
.editor .play-head-wrap,
.editor .playhead-time-wrap {
  width: 90%;
  margin: auto;
  display: block;
  position: relative;
}
.editor .video-controls {
  position: relative;
}
.editor #playhead {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 500;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3px 10px 3px;
  border-color: transparent transparent #3264fe transparent;
}
.editor #playhead-time {
  position: absolute;
  top: -13px;
  left: 0;
  z-index: 500;
  width: 0;
  height: 0;
  font-size: 0.6rem;
  width: 52px;
  height: 10px;
  opacity: 0;
  transition: opacity 1s;
}
.editor #playhead-time.on {
  opacity: 1;
}
.editor #playhead-time #play-time-text {
  position: absolute;
  left: -19px;
}
/* 
.editor .tools {
  text-align: center;
  border-top: solid 1px #262525;
  padding: 0.5rem;
}
.editor .tools .tool {
  display: inline-flex;
  padding: 0.5em 0.7em 0.3em;
  border-radius: 8px;
  border: solid 1px transparent;
  margin-left: 5px;
}
.editor .tools .tool.on {
  border: solid 1px #262525;
  background-color: #151313;
  background-color: #181818;
}
.editor .tools i {
  pointer-events: none;
}
.editor .tools label {
  margin-left: 5px;
  font-size: 0.8em;
  text-transform: uppercase;
  pointer-events: none;
}
 */
.editor-menu {
  background-color: #151313;
  width: 250px;
  border-top: solid 1px #262525;
  border-right: solid 1px #262525;
  border-bottom: solid 1px #262525;
  height: 675px;
  overflow: auto;
  border-radius: 0 5px 5px 0;
  -webkit-box-shadow: 0px 5px 4px -1px rgba(0, 0, 0, 0.53);
  box-shadow: 0px 5px 4px -1px rgba(0, 0, 0, 0.53);
  margin-left: -250px;
  transition: margin-left 0.3s ease-in-out;
}
.editor-menu.on {
  margin-left: 0;
}
.editor-menu .editor-section-header {
  padding: 10px;
  background-color: #101010;
  border-bottom: solid 1px #262525;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
}
.editor-menu .keyframe-list {
  height: 340px;
  overflow: auto;
  border-bottom: solid 1px #262525;
}
.editor-menu .keyframe-list.hide {
  display: none;
}
.editor-menu .keyframe-list .keyframe-item {
  padding: 0.7rem 1rem;
  font-size: 0.9rem;
  border-bottom: solid 1px #262525;
}
.editor-menu .keyframe-list .keyframe-item.selected {
  background-color: #623232;
}
.editor-menu .keyframe-list .keyframe-item .timecode-small {
  font-size: 0.7rem;
}
.editor-menu .keyframe-list .keyframe-item span {
  pointer-events: none;
}
.editor-menu .caption-wrap.hide {
  display: none;
}
.editor-menu .caption-list {
  height: 260px;
  overflow: auto;
}
.editor-menu .caption-list .caption-item {
  padding: 0.7rem 1rem;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  border-bottom: solid 1px #262525;
}
.editor-menu .caption-list .caption-item label.title {
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #9b9b9b;
}
.editor-menu .caption-list .caption-item .left-content {
  flex-basis: 70%;
  display: flex;
  align-items: center;
}
.editor-menu .caption-list .caption-item .right-content {
  flex-basis: 30%;
  display: flex;
  justify-content: flex-end;
}
.editor-menu .caption-list .caption-item > .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.editor-menu .caption-list .caption-item input[type="range"] {
  width: 100%;
  height: 3px;
}
.editor-menu .caption-list .font-list {
  max-height: 90px;
  overflow-y: auto;
  overflow-x: hidden;
}
.editor-menu .caption-list .font-list button {
  padding: 0.3rem 0.5rem;
  border: none;
  background-color: #378dd9;
  font-weight: 700;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: white;
}
.editor-menu .caption-list .font-list .font-item {
  font-size: 1rem;
  padding: 0.3rem 1rem;
  border-bottom: solid 1px #262525;
  color: yellow;
}
.editor-menu .caption-list .font-list .font-item.on {
  background-color: #623232;
}

.video-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8999;
  background-color: rgba(28, 27, 27, 0.99);
  /* background-image: url("../img/video-bg.png"); */
  width: 105%;
  height: 105%;
}

.video-modal.show {
  display: block;
}

.crop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust the height as per your requirement */
}
.crop-container .top {
  grid-area: top;
  background-color: lightblue;
}
.crop-container .left {
  grid-area: left;
  background-color: lightblue;
}
.crop-container .center {
  grid-area: center;
  background-color: lightgreen;
}
.crop-container .right {
  grid-area: right;
  background-color: lightblue;
}
.crop-container .bottom {
  grid-area: bottom;
  background-color: lightblue;
}

*[data-tooltip].top:before,
*[data-tooltip].top:after {
  transform: translateY(10px);
}
*[data-tooltip].top:hover:after,
*[data-tooltip].top:hover:before {
  transform: translateY(0px);
}

*[data-tooltip].right:before,
*[data-tooltip].right:after {
  transform: translateX(0px);
}
*[data-tooltip].right:hover:after,
*[data-tooltip].right:hover:before {
  transform: translateX(10px);
}

*[data-tooltip].bottom:before,
*[data-tooltip].bottom:after {
  transform: translateY(-10px);
}
*[data-tooltip].bottom:hover:after,
*[data-tooltip].bottom:hover:before {
  transform: translateY(0px);
}

*[data-tooltip].left:before,
*[data-tooltip].left:after {
  transform: translateX(0px);
}
*[data-tooltip].left:hover:after,
*[data-tooltip].left:hover:before {
  transform: translateX(-10px);
}

*[data-tooltip] {
  position: relative;
}
*[data-tooltip]:after,
*[data-tooltip]:before {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: transform 200ms ease, opacity 200ms;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 99;
}
*[data-tooltip]:before {
  content: attr(data-tooltip);
  background: #191717;
  color: #fff;
  font-size: 0.5rem;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}
*[data-tooltip]:after {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  content: "";
}
*[data-tooltip]:hover:after,
*[data-tooltip]:hover:before {
  visibility: visible;
  opacity: 0.85;
  transform: translateY(0px);
}

*[data-tooltip][data-position="top"]:before {
  bottom: 100%;
  left: -130%;
  margin-bottom: 10px;
}

*[data-tooltip][data-position="top"]:after {
  border-top-color: #191717;
  border-bottom: none;
  bottom: 101%;
  left: calc(50% - 6px);
  margin-bottom: 4px;
}

*[data-tooltip][data-position="left"]:before {
  top: -12%;
  right: 100%;
  margin-right: 10px;
}

*[data-tooltip][data-position="right"]:before {
  top: -5%;
  left: 100%;
  margin-left: 10px;
}

*[data-tooltip][data-position="right"]:after {
  border-right-color: #191717;
  border-left: none;
  top: calc(50% - 6px);
  left: calc(100% + 4px);
}

*[data-tooltip][data-position="bottom"]:before {
  top: 100%;
  left: -130%;
  margin-top: 10px;
}

*[data-tooltip][data-position="bottom"]:after {
  border-bottom-color: #191717;
  border-top: none;
  top: 100%;
  left: 5px;
  margin-top: 4px;
}

.section-header {
  padding: 12.5px;
  background-color: #340038;
  margin-bottom: 1rem;
  border-bottom: solid 1px #3c3b3b;
}
.section-header .headline {
  font-size: 2rem;
  font-weight: 500;
  text-transform: capitalize;
  text-transform: capitalize;
}

.captions {
  position: absolute;
  bottom: 5%;
  transform: translateY(-50%);
  max-width: 40%;
  text-align: center;
  display: none;
}
.captions.on {
  display: initial;
}

.caption-style1 {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: yellow;
  background-color: black;
  padding: 5px;
  opacity: 0.8;
  text-transform: uppercase;
}

.caption-style2 {
  font-family: "Bebas Neue";
  font-size: 24px;
  color: yellow;
  background-color: none;
  padding: 5px;
  opacity: 0.8;
  text-transform: uppercase;
  text-shadow: 1px 2px 1px rgb(0, 0, 0);
}

::cue {
  font-family: "BadaBoom BB";
  color: #ffcc00;
  background-color: transparent;
  outline: black;
  line-height: 2rem;
  text-shadow: -1px 2px 3px #000000;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.flashing-text {
  animation: flash 2s infinite;
}

.range-container {
  position: relative;
  width: 90%;
  height: 10px;
  margin: 6px auto;
}
.range-container input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}
.range-container .slider-track {
  width: 100%;
  height: 8px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  /* border-radius: 5px; */
  background-color: white;
}
.range-container input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}
.range-container input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}
.range-container input[type="range"]::-ms-track {
  appearance: none;
  height: 5px;
}
.range-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.6em;
  width: 0.5em;
  background-color: #3264fe;
  cursor: pointer;
  margin-top: -8px;
  pointer-events: auto;
  /* border-radius: 50%; */
}
.range-container input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  cursor: pointer;
  border-radius: 50%;
  background-color: #3264fe;
  pointer-events: auto;
}
.range-container input[type="range"]::-ms-thumb {
  appearance: none;
  height: 1.7em;
  width: 1.7em;
  cursor: pointer;
  border-radius: 50%;
  background-color: #3264fe;
  pointer-events: auto;
}
.range-container input[type="range"]:active::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 3px solid #3264fe;
}
.values {
  width: 100%;
  position: relative;
  margin: auto;
  padding: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}
