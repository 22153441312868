.pages-wrap {
  width: 80%;
  padding: 10px;
  margin: 20px auto;

  @media (max-width: 1100px) {
    width: 90%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    gap: 20px;
  }

  .col {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.6s ease;
    border-radius: 5px;

    &.buy {
      @media (max-width: 1100px) {
        border: none;
      }

      &:hover {
        @media (min-width: 1101px) {
          background-color: #364046;
        }
      }
    }

    @media (min-width: 1101px) {
      flex-basis: calc(
        33.333% - 20px
      ); /* Set the flex basis to 33.333% for screens larger than 1100px */
    }

    @media (max-width: 1100px) {
      flex-basis: calc(50% - 20px);
    }

    @media (max-width: 800px) {
      flex-basis: 100%;
    }

    &.centered {
      display: flex; /* Add display:flex to the .col element */
      flex-direction: column; /* Optional, for vertical alignment */
      align-items: center; /* Center content vertically */
    }

    &.full {
      p {
        width: 70%;
        margin-bottom: 20px;
        font-weight: 700;

        @media (max-width: 1100px) {
          width: 80%;
        }

        @media (max-width: 800px) {
          width: 100%;
        }

        span {
          font-size: 90%;
          font-weight: 500;
        }
      }
    }
  }

  &.settings {
    font-size: 1.1rem;

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }

    input[type="text"] {
      box-shadow: inset 0px 0px 4px 0px #000000;
      border: solid 1px #34404d !important;
      padding: 0.5rem 1rem;
      margin: 0.5rem auto;
      border: none;
      background-color: rgb(85, 85, 87);
      color: white;
    }

    button {
      width: auto;
    }
  }

  h1 {
    font-size: 1.6em;
    text-transform: uppercase;
  }

  h2 {
    font-size: 1.3em;
    margin-bottom: 20px;
  }

  a {
    width: 100%;
    button {
      width: 100%;
    }
  }
  span.button,
  a.button {
    display: inline-block;
    text-align: center;
    font-family: inherit;
  }

  span.button,
  a.button,
  button {
    font-size: 0.85rem;
    margin: 20px auto 10px;
    width: 100%;
    border-radius: 4px;
    background-color: #56ff00;
    padding: 10px 20px;
    color: black;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: $green;
    }

    &.disabled {
      background-color: grey;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }

  li {
    margin-bottom: 10px;

    span {
      margin-left: 10px;
    }
  }

  hr {
    width: 100%;
  }
}

.sale-price {
  font-size: 2em;
  display: block;
  font-weight: 600;
  margin: 20px auto;
  color: pink;

  .cents {
    font-size: 70%; /* Adjust the font size as needed */
    vertical-align: top; /* Shift it to the top of the line */
  }

  .small {
    font-size: 0.6em;
  }
}

.sale-description {
  font-size: 0.9em;
  display: block;
}

.tos {
  h1,
  h3 {
    margin-bottom: 20px;
  }
  h2,
  p {
    margin-bottom: 20px;
    font-size: 0.9em;
  }
  h2 {
    font-size: 1em;
  }
  h3 {
    font-size: 1em;
  }

  li {
    margin-left: 50px;
  }
}
