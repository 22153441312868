$cameraBorderColor: #272c33;
$cameraBackgroundColor: #171b21;
.video-wrap {
  .grid_1,
  .grid_2,
  .grid_3,
  .grid_4,
  .grid_5,
  .grid_6,
  .grid_7,
  .grid_8 {
    position: absolute;

    img,
    video {
      object-fit: cover;
      aspect-ratio: initial;
      height: 100%;
    }

    .off {
      right: 100%;
    }

    &.share {
      z-index: 5 !important;
    }

    .user-img-wrap {
      object-fit: cover;
      height: 100%;
      width: 100%;
      aspect-ratio: 16/9;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      background-color: $cameraBackgroundColor;
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      top: 0;
      border: none;

      video {
        object-fit: cover;
      }
    }

    &.pip {
      top: initial !important;
      left: 0.5vw !important;
      bottom: 2.5vw !important;
      width: 25% !important;
      height: 30% !important;
      z-index: 15 !important;
    }
  }

  .grid_1 {
    &.host {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-right: none;

      &.has-solo {
        left: -100%;
      }
    }

    &:nth-child(n + 2) {
      left: 100%;
    }
  }

  .grid_2 {
    &.host {
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-right: solid 1px $cameraBorderColor;
    }

    &.guest {
      width: 50%;
      height: 100%;
      left: 50%;
      top: 0;

      &.solo {
        left: 0 !important;
        width: 100% !important;
        height: 100%;
        top: 0;
        border: none;

        video {
          object-fit: cover;
        }
      }

      &:nth-child(n + 3) {
        left: 100%;
      }
    }

    &.has-solo {
      &.guest {
        left: 100%;
      }
      &.host {
        left: -50%;
      }
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      top: 0;
      border: none;

      video {
        object-fit: cover;
      }
    }
  }

  .grid_3 {
    &.host {
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-right: solid 1px $cameraBorderColor;
    }

    &.guest {
      top: 0;
      left: 50%;
      width: 50%;
      height: 50%;
      border-bottom: solid 1px $cameraBorderColor;

      &:nth-of-type(3) {
        top: 50%;
        border-bottom: none;
      }

      &:nth-of-type(n + 4) {
        left: 100%;
      }
    }

    &.has-solo {
      &.guest {
        left: 100%;
      }

      &.host {
        left: -50%;
      }
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      border: none;

      video {
        object-fit: cover;
      }
    }
  }

  .grid_4 {
    &.host {
      left: 0;
      width: 65%;
      height: 100%;
      border-right: solid 1px $cameraBorderColor;
    }

    &.guest {
      top: 0;
      left: 65%;
      width: 35%;
      height: 33.333%;
      border-bottom: solid 1px $cameraBorderColor;

      &:nth-of-type(3) {
        left: 65%;
        top: 33.333%;
        border-bottom: solid 1px $cameraBorderColor;
      }

      &:nth-of-type(4) {
        left: 65%;
        top: 66.666%;
      }

      &:nth-of-type(n + 5) {
        left: 100%;
      }
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      top: 0;
      border: none;

      video {
        object-fit: cover;
      }
    }
  }

  .grid_5 {
    &.host {
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-right: solid 1px $cameraBorderColor;
    }

    &.guest {
      width: 25%;
      height: 50%;
      left: 50%;
      top: 0;
      border-right: solid 1px $cameraBorderColor;
      border-bottom: solid 1px $cameraBorderColor;

      &:nth-of-type(3) {
        left: 75%;
        top: 0;
        border-bottom: solid 1px $cameraBorderColor;
      }

      &:nth-of-type(4) {
        left: 50%;
        top: 50%;
        border-right: solid 1px $cameraBorderColor;
      }

      &:nth-of-type(5) {
        left: 75%;
        top: 50%;
      }
      &:nth-child(n + 6) {
        left: 100%;
      }
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      top: 0;
      border: none;

      video {
        object-fit: cover;
      }
    }
  }

  .grid_6 {
    &.host {
      top: 0;
      left: 0;
      width: 33%;
      height: 50%;
      border-right: solid 1px $cameraBorderColor;
      border-bottom: solid 1px $cameraBorderColor;
    }

    &.guest {
      top: 0;
      left: 33.333%;
      width: 33%;
      height: 50%;
      border-right: solid 1px $cameraBorderColor;
      border-bottom: solid 1px $cameraBorderColor;

      &:nth-child(2) {
        left: 66.666%;
        top: 0;
        border-bottom: solid 1px $cameraBorderColor;
      }

      &:nth-child(3) {
        left: 0;
        top: 50%;
        border-right: solid 1px $cameraBorderColor;
      }

      &:nth-child(4) {
        left: 33.333%;
        top: 50%;
        border-right: solid 1px $cameraBorderColor;
      }
      &:nth-child(5) {
        left: 66.666%;
        top: 50%;
      }
      &:nth-child(n + 6) {
        right: -50%;
      }
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      top: 0;
      border: none;
      video {
        object-fit: cover;
      }
    }
  }

  .grid_7 {
    &.host {
      width: 40%;
      height: 100%;
    }

    &.guest {
      left: 40%;
      top: 0;
      width: 20%;
      height: 50%;

      &:nth-child(2) {
        left: 60%;
        top: 0;
      }

      &:nth-child(3) {
        left: 80%;
        top: 0;
      }

      &:nth-child(4) {
        left: 40%;
        top: 50%;
      }
      &:nth-child(5) {
        left: 60%;
        top: 50%;
      }
      &:nth-child(6) {
        left: 80%;
        top: 50%;
      }

      &:nth-child(n + 7) {
        right: -50%;
      }
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      top: 0;
      border: none;
      video {
        object-fit: cover;
      }
    }
  }

  .grid_8 {
    &.host {
      top: 0;
      left: 0;
      width: 25%;
      height: 50%;
    }

    &.guest {
      left: 25%;
      width: 25%;
      height: 50%;
      top: 0;

      &:nth-child(2) {
        left: 50%;
      }

      &:nth-child(3) {
        left: 70%;
      }

      &:nth-child(4) {
        left: 0;
        top: 50%;
      }

      &:nth-child(5) {
        left: 25%;
        top: 50%;
      }

      &:nth-child(6) {
        left: 50%;
        top: 50%;
      }

      &:nth-child(7) {
        left: 75%;
        top: 50%;
      }
    }

    &.solo {
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      top: 0;
      border: none;

      video {
        object-fit: cover;
      }
    }
  }
}
