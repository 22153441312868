.rundown {
  &.sporty {
    width: 30%;
    font-size: 32px;
    position: fixed;
    bottom: 52px;
    right: -150%;
    border-left: solid 3px rgb(0, 0, 0);
    border-top: solid 8px rgb(0, 0, 0);
    transition: all 0.6s;

    &.on {
      right: 0px;
    }

    ul {
      li {
        list-style: none;
        padding: 20px 10px;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: 2px 2px 4px #000000;
        background-color: rgba(159, 39, 39, 0.83);
        transition: all 0.6s;
        width: 100%;
        border-bottom: solid 1px black;
        &.selected {
          background-color: #fe6100ea;
          color: white;
        }
      }
    }
  }
}

.scrolling-text-container {
  &.sporty {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-color: #fe6000;
    font-weight: 700;
    text-transform: uppercase;
    border-top: solid 3px black;

    .ticker {
      width: auto;
      overflow: hidden;
      margin: 10px auto 10px auto;
    }

    .marquee {
      font-size: 1.5em;
      text-shadow: 2px 2px 4px #000000;
    }
  }
}

.topic-container {
  &.sporty {
    display: flex;
    position: fixed;
    bottom: 52px;
    left: -100%;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: left;
    width: 100%;
    transition: all 0.6s;

    &.on {
      left: 0px;
    }

    .topic-source {
      box-shadow: inset -2px 0px 9px -2px #000000;
      background: linear-gradient(
        90deg,
        rgba(180, 9, 9, 0.849) 0%,
        rgba(201, 78, 78, 0.872) 52%,
        rgba(159, 2, 2, 0.838) 100%
      );
      color: white;
      text-shadow: 2px 2px 4px #000000;
      padding: 30px 20px;
      font-size: 48px;
      font-weight: 800;
      width: 100%;
      border-top: solid 3px black;
      margin-bottom: 0;
      display: flex;
      justify-content: left;
      align-items: left;

      span {
        max-width: 80%;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }
}
