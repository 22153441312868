.teleprompter-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  border: 1px solid #ccc;
  z-index: 9000;
}

.prompter-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 20px;
  border-bottom: solid 1px white;
  z-index: 9999;
  background-color: black;

  span {
    display: block;
    font-size: 0.8em;
  }

  .col {
    width: auto;
    margin-right: 10px;
  }

  .topic-time {
    position: fixed;
    top: 0;
    right: 0;
    padding: 10px 20px;

    @media (max-width: 480px) {
      font-size: 0.8em;
    }
  }
}

.teleprompter {
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  white-space: pre-line;
  z-index: 9500;
  top: 65px;
  left: 0;
  height: 98%;
  width: auto;
  background-color: black;
  overflow-wrap: anywhere;

  p {
    margin: 60px auto;
    width: 80%;
    font-family: sans-serif;
    font-size: 80px;
    line-height: 120px;
    color: rgba(255, 255, 255, 0.9);
    font-family: ratio, sans-serif;
    font-style: normal;
    font-weight: 500;
    transition: all 0.2s;

    &:focus {
      outline: none;
    }
  }
}
