.rundown {
  &.playhouse {
    font-family: "Dela Gothic One", cursive;
    text-shadow: 2px 6px 10px #000000;
    width: 400px;
    font-size: 20px;
    position: absolute;
    top: -100%;
    left: 30px;
    padding: 10px;
    background: none;
    border: 37px solid;
    border-image: url("./img/play-house-border.png") 50 round;
    border-image-width: 50px;
    transition: all 0.6s ease-in-out;
    &.on {
      top: 5%;
    }

    ul {
      li {
        margin: 5px;
        list-style: none;
        padding: 10px 10px;
        color: #efeb23;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 0.6s;
        border-radius: 8px;
        width: auto;
        filter: drop-shadow(2px 4px 3px #000000c7);
        &.selected {
          background: linear-gradient(
            -45deg,
            #ecde1b,
            #ee0404,
            #06f832,
            hsl(203, 100%, 50%)
          );
          animation: playhouse-gradient 20s ease infinite;
          background-size: 400% 400%;

          color: white;
          margin: auto;
          border: solid 1px rgba(83, 8, 68, 0.911);
        }
      }
    }
  }
}

.scrolling-text-container {
  &.playhouse {
    font-family: "Dela Gothic One", cursive;
    background: none;
    width: 100%;
    position: fixed;
    bottom: 0px;
    color: white;
    text-shadow: 2px 2px 10px #000000;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 3px;

    .marquee {
      font-size: 20px;
      letter-spacing: 0.2em;
    }

    .ticker {
      background-color: #1086e7;
      width: auto;
      overflow: hidden;
      margin: 0;
      padding: 10px;
    }
  }
}

.topic-container {
  &.playhouse {
    font-family: "Dela Gothic One", cursive;
    display: flex;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: center;

    &.on {
      bottom: 40px;
    }

    .topic-source {
      color: white;
      text-shadow: 2px 2px 4px #000000;
      background-size: 200% 200%;
      padding: 0px;
      font-size: 32px;
      font-weight: 800;
      width: 60%;
      border: 50px solid;
      border-image: url("./img/play-house-border.png") 50 round;
      border-image-width: 50px;

      margin-bottom: 20px;

      /* Flexbox properties for vertical alignment */
      display: flex;
      justify-content: center; /* Horizontally center content */
      align-items: center; /* Vertically center content */

      span {
        background-color: #1086e7;
        box-shadow: inset 1px 3px 8px 0px #000000, 1px 6px 7px -2px #000000;
        display: block;
        width: 100%;
        padding: 20px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 8px;
      }
    }
  }
}

@keyframes playhouse-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
