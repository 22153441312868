.rundown {
  &.corp {
    width: 400px;
    font-size: 28px;
    position: absolute;
    top: 5%;
    left: -150%;
    transition: all 0.6s;

    &.studio {
      position: absolute;
      font-size: 0.8vw;
      width: 13vw;
      border-left: solid 0.2vw #3292f8;
      ul {
        li {
          width: 100%;
          border: solid 0.2vw #3292f8;
          padding: 0.5vw;
          margin: 0.5vw;
        }
      }

      &.on {
        left: 15px;
      }

      &.emdeded {
        font-size: 1.4vw;
        width: 19vw;
      }
    }

    @media (min-width: 3840px) {
      font-size: 60px;
      width: 910px;
    }

    &.on {
      left: 30px;
    }

    ul {
      li {
        margin: 10px;
        list-style: none;
        padding: 10px 10px;
        color: black;
        font-weight: 700;
        text-transform: uppercase;
        background-color: white;
        box-shadow: inset 0px 3px 5px 0px #000000, 1px 1px 3px 0px #000000;
        border: solid 4px #3292f8;
        transition: all 0.6s;
        width: 375px;
        font-size: 90%;

        &.selected {
          background-color: #3292f8;
          color: white;
          margin-left: 30px;
        }
      }
    }
  }
}

.scrolling-text-container {
  &.corp {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-color: white;
    font-weight: 700;
    text-transform: uppercase;
    border-top: solid 4px #3292f8;
    color: black;

    &.studio {
      position: absolute;
      font-size: 0.7vw;
      border-top: solid 0.2vw #3292f8;

      .marquee {
        z-index: initial;
      }

      &.emdeded {
        font-size: 1vw;
      }
    }

    .ticker {
      width: auto;
      overflow: hidden;
      margin: 5px auto 5px auto;
    }

    .marquee {
      font-size: 1.2em;
    }
  }
}

.topic-container {
  &.corp {
    display: flex;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: center;
    font-size: 2em;

    &.studio {
      position: absolute;

      .topic-source {
        font-size: 1.2vw;
        width: 60%;
        border: solid 0.4vw #3292f8;
        padding: 0.8vw;
      }

      &.on {
        bottom: 1.5vw;
      }

      &.emdeded {
        .topic-source {
          font-size: 2.2vw;
          width: 60%;
          border: solid 0.4vw white;
          padding: 0.8vw;
        }

        &.on {
          bottom: 2.5vw;
        }
      }
    }

    &.on {
      bottom: 56px;
    }

    .topic-source {
      box-shadow: inset 0px 3px 5px 0px #000000, 1px 1px 3px 0px #000000;
      background-color: white;
      color: black;
      padding: 20px;

      font-weight: 800;
      max-width: 900px;
      width: 900px;
      border: solid 6px #3292f8;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        max-width: 80%;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
