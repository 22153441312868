@import "_variables";
@import "mixins";

.room,
.control-center {
  .settings {
    background-color: #1f2935;
  }

  .media-wrap {
    display: flex;

    .col {
      width: 80%;

      &.last {
        width: 10%;
      }
    }
  }

  .column {
    &.full {
      .chat-wrap {
        max-height: 93vh;

        .chat-list {
          max-height: initial;
        }
      }
    }

    &.guests {
      padding: 0.5rem;

      @media (max-width: 500px) {
        padding: 0;
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        top: 0px;
        background-color: $primary-background-color;
        border-bottom: solid 1px #262627;
        z-index: 200;
      }
    }
  }

  .room-info {
    label {
    }

    &.green-room {
      .guests {
        .wrap {
          width: calc(50% - 1rem);
          padding: 0.5px;
          background-color: red;
          padding: 0.5rem;
          min-width: calc(50% - 1rem);
          background-color: #708570;
          border: solid 1px #4a514a;
          margin: 0.5rem auto;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          position: relative;
          border-radius: 3px;

          &.stage {
            background-color: #707685;
          }

          &.green-room {
            background-color: #708570;
          }
        }

        .video-window {
          video {
            width: 100%;

            &.use-image {
              display: none;
            }
          }
          .name-wrap {
            span {
              display: block;
            }
          }

          .muted-video {
            position: absolute;
            top: 0.7rem;
            left: 0.7rem;
          }

          .user-tools-ctrl {
            position: absolute;
            top: 0.7rem;
            right: 0.7rem;
            cursor: pointer;
            background-color: #798fa7;
            border: solid 1px grey;
            width: 25px;
            height: 25px;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 5px;
            color: black;
          }

          .user-tools {
            position: absolute;
            top: 0.7rem;
            right: 0.7rem;
            width: 110px;
            background-color: #445263;
            border: solid 1px #14202e;
            list-style: none;
            font-size: 0.7rem;
            text-transform: uppercase;
            -webkit-box-shadow: $shadow;
            box-shadow: $shadow;

            li {
              border-bottom: solid 1px #14202e;
              padding: 0.5rem 0.5rem;
              cursor: pointer;

              &:hover {
                color: black;
                font-weight: 700;
                background-color: #d94848;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          .vid-img {
            background-color: black;
            display: none;

            &.use-image {
              width: 100%;
              display: block;
            }

            img {
              width: 100%;
            }
          }

          .user-img-wrap {
            /*  width: 100%;
            height: auto;
            aspect-ratio: 16/9;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
            background-color: #14202e; */
          }

          .hide {
            display: none;
          }

          .circle-container {
            width: 40px; /* Set the width and height of the container to create a circular shape */
            height: auto;
            aspect-ratio: 1;
            overflow: hidden; /* Hide the overflow to create a circular mask */
            border-radius: 50%; /* Make the container circular using border-radius */
            border: solid 3px white;

            img {
              width: 100%; /* Make the image fill the circular container */
              height: auto !important; /* Maintain the aspect ratio of the image */
              border-radius: 50%; /* Apply the circular mask to the image */
              object-fit: cover; /* Ensure the image covers the entire circular area */
            }
          }
        }
      }
    }
  }

  iframe {
    width: 100%;
    height: 93vh;
    border: none;
  }

  .video-wrap {
    /* aspect-ratio: 16 / 9;

    width: 100%;
    max-width: 1360px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative; */

    .elapsed-show-time {
      position: absolute;
      top: 1vw;
      left: 1vw;
      z-index: 20;
      background-color: red;
      border-radius: 8px;
      font-size: 0.8rem;
      padding: 4px 6px;
      font-weight: 700;

      @media (max-width: 640px) {
        font-size: 0.7rem;
      }
    }

    img {
      &.placeholder {
        max-width: 100%;
      }
    }

    &.grid_3,
    &.grid_4,
    &.grid_5,
    &.grid_7 {
      flex-direction: column;
    }

    .video-window {
      .level-wrap {
        position: absolute;
        bottom: -5px;
        right: 0;
      }

      .muted-video {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
      }

      &.host {
        order: -10;
      }

      video {
        &.use-image {
          display: none;
        }

        &.hide {
          display: none;
        }
      }

      .vid-img {
        display: none;
        &.use-image {
          width: 100%;
          height: 100%;
          display: initial;
        }
      }

      img {
        width: 100%;
        @include transition(all 0.4s ease-in-out);
        &.hide {
          display: none;
        }
      }
    }
  }

  select {
    max-width: 200px;
    width: 100%;
  }

  .inner {
    padding: 10px;
  }

  h2 {
    font-size: 1.3rem;
  }
  label {
    font-size: 1rem;
  }

  .cam-scene-ctrl {
    background-color: #14202e;

    .cam-scene-btn {
      img {
        max-width: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }
}

/* .name-wrap {
  font-size: 0.8vw;
  position: absolute;
  top: -25vw;
  right: 0.5vw;
  @include transition(all 0.4s ease-in-out);
  text-align: right;

  &.on {
    top: 0.5vw;
    position: absolute;
  }

  .display-name {
    display: block;
    padding: 0.3rem 0.5rem 0.3rem;
    background-color: rgba(0, 0, 0, 0.861);
    min-width: 5vw;
  }
  .tag-line {
    display: block;
    font-size: 75%;
    padding: 0.3rem 0.5rem;
    background-color: rgba(255, 0, 0, 0.861);
  }
} */

.toolTip {
  font-size: 0.8rem !important;
  background-color: #181818fc !important;
  @include transition(all 0.4s ease-in-out);
}

.user-img-wrap {
  /* height: 200px; */
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #14202e;
}

.hide {
  display: none;
}

.circle-container {
  width: 40%; /* Set the width and height of the container to create a circular shape */
  max-width: 300px;
  height: auto;
  overflow: hidden; /* Hide the overflow to create a circular mask */
  border-radius: 50%; /* Make the container circular using border-radius */
  border: solid 3px white;

  img {
    width: 100%; /* Make the image fill the circular container */
    height: auto !important; /* Maintain the aspect ratio of the image */
    border-radius: 50%; /* Apply the circular mask to the image */
    object-fit: cover; /* Ensure the image covers the entire circular area */
  }
}

.settings {
  width: 100%;
  height: 95.5vh;
  display: flex;

  z-index: 300;

  .wrap {
    max-width: 550px;
    border: solid 1px #3d6083;

    .header {
      padding: 10px;
      font-weight: 700;
      background-color: #1a2735;
      width: 100%;
    }
  }

  .level-wrap {
    max-width: 200px;
  }
}
