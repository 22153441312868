.rundown {
  &.pinky {
    width: 400px;
    font-size: 24px;
    position: absolute;
    top: 10%;
    left: -130%;
    padding: 10px;
    border: none;
    border-radius: 8px;
    transition: all 0.6s;

    &.on {
      left: 30px;
    }

    ul {
      li {
        margin: 5px;
        list-style: none;
        padding: 10px 10px;
        color: black;
        font-weight: 700;
        text-transform: uppercase;
        background-color: rgba(235, 165, 221, 0.94);
        transition: all 0.6s;
        border-radius: 8px;
        width: auto;
        filter: drop-shadow(2px 4px 3px #000000c7);
        &.selected {
          background: linear-gradient(
            -45deg,
            #ec1bec,
            #990369,
            #ec1bec,
            #990369,
            #ec1bec
          );
          animation: pinky-gradient 20s ease infinite;
          background-size: 400% 400%;

          color: white;
          margin: auto;
          border: solid 1px rgba(83, 8, 68, 0.911);
        }
      }
    }
  }
}

.scrolling-text-container {
  &.pinky {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-color: black;
    font-weight: 700;
    text-transform: uppercase;
    background-color: rgba(235, 165, 221, 0.94);
    animation: pinky-gradient 10s ease infinite;
    background-size: 400% 400%;
    color: #5f0342;
    border-top: solid 8px #5f0342;

    .marquee {
      font-size: 1.5em;
    }

    .ticker {
      width: auto;
      overflow: hidden;
      margin: 10px auto 10px auto;
    }
  }
}

.topic-container {
  &.pinky {
    display: flex;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: center;

    &.on {
      bottom: 35px;
    }

    .topic-source {
      background: rgb(180, 9, 9);
      background: linear-gradient(
        -45deg,
        #ec1bec,
        #990369,
        #ec1bec,
        #990369,
        #ec1bec
      );
      animation: pinky-gradient 20s ease infinite;
      background-size: 400% 400%;
      color: white;
      text-shadow: 2px 2px 4px #000000;
      padding: 20px;
      font-size: 48px;
      font-weight: 800;
      max-width: 100%;
      width: 100%;
      border-top: solid 11px #5f0342;
      border-bottom: solid 11px #5f0342;
      border-right: none;
      border-left: none;
      margin-bottom: 20px;

      /* Flexbox properties for vertical alignment */
      display: flex;
      justify-content: center; /* Horizontally center content */
      align-items: center; /* Vertically center content */

      span {
        max-width: 80%;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

@keyframes pinky-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
