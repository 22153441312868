.room,
.join-room {
  @media (max-width: 640px) {
  }

  .guest-controls {
    /* padding: 0.4rem 2rem;
    position: fixed;
    bottom: 0;
    background-color: #1f2935;
    border-top: solid 1px #2e4155;
    border-left: solid 1px #2e4155;
    border-right: solid 1px #2e4155;
    border-radius: 5px 5px 0 0; */

    span {
      border: solid 1px #2e4155;
      border-radius: 50%;
      padding: 0.5rem;
      margin-right: 0.5rem;
      width: 45px;
      height: 45px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &.red {
        background-color: red;
      }
    }

    .private-chat {
      display: none;
      @media screen and (max-width: 500px), screen and (max-height: 500px) {
        display: inline-flex;
      }
    }
  }

  .guests.float {
    /* position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    display: flex; */

    @media (max-width: 920px) {
      display: none;
    }

    .wrap {
      /* padding: 0.5px;

      text-align: center;
      padding: 0.5rem;
      background-color: #708570;
      border: solid 1px #4a514a;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */

      &.stage {
        display: none;
      }
    }

    .guest-details {
      /* align-self: flex-start;
      padding: 0.5rem;
      background-color: #345883;
      margin-left: 5px;
      font-size: 0.9rem;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */

      &.stage {
        bottom: 0.5vh;
        position: fixed;
        background-color: red;
      }
    }

    .video-window {
      position: relative;
      max-width: 200px;
      video {
        max-width: 200px;

        @media (max-width: 900px) {
          max-width: 100px;
        }

        &.use-image {
          display: none;
        }
      }
      .name-wrap {
        text-align: left;
        font-size: 0.9rem;
        span {
          display: block;
        }
        .tag-line {
          display: none;
        }
      }
      .muted-video {
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
      }

      .vid-img {
        background-color: black;
        display: none;

        &.use-image {
          width: 100%;
          display: block;
        }

        img {
          width: 100%;
        }
      }

      .user-img-wrap {
        width: 200px;
        height: 100%;
        aspect-ratio: 16/9;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        background-color: #14202e;
      }

      .hide {
        display: none;
      }

      .circle-container {
        width: 50%; /* Set the width and height of the container to create a circular shape */
        height: auto;
        aspect-ratio: 1;
        overflow: hidden; /* Hide the overflow to create a circular mask */
        border-radius: 50%; /* Make the container circular using border-radius */
        border: solid 3px white;

        img {
          width: 100%; /* Make the image fill the circular container */
          height: auto !important; /* Maintain the aspect ratio of the image */
          border-radius: 50%; /* Apply the circular mask to the image */
          object-fit: cover; /* Ensure the image covers the entire circular area */
        }
      }
    }
  }
}

.hidden-cav {
  display: none;
}

.join-room {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 2rem 1rem;
}

.settings {
  display: flex;

  @media (max-width: 500px) {
    display: block;
    width: 100%;
  }

  .join-panel {
    height: 100%;
    min-width: 400px;
    padding: 1rem;
    background-image: linear-gradient(
      to bottom,
      #051937,
      #002862,
      #00378f,
      #0045bd,
      #0e52ec
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: solid 2px #93a7eb;

    @media (max-width: 500px) {
      display: block;
      width: 100%;
      min-width: auto;
      border-right: none;
    }

    .icon {
      margin-right: 1rem;
      cursor: pointer;
    }

    .join-media-wrap {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      align-items: end;
      margin-bottom: 0.5rem;

      .mediaSettings {
        display: flex;
        align-items: center;
        flex-direction: column;

        .level-wrap {
          justify-content: center;
          align-items: center;
          display: flex;
          margin-top: 0.5rem;
          min-height: 120px;
        }
      }
    }

    .video-window {
      max-width: 100%;
      max-height: 200px;
      justify-content: center;
      display: flex;
    }

    video {
      height: 200px;
      &.hide {
        display: none;
      }
    }

    img {
      width: 100%;
      height: 200px;
      &.hide {
        display: none;
      }
    }

    input {
      padding: 0.3rem;
      background-color: #b2bee9;
      border: none;
      border-radius: 2px;
    }

    .room-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      label {
        display: block;
        font-size: 2.4rem;
        margin-bottom: 0.2rem;
        text-transform: uppercase;
        font-weight: 700;
      }
      input {
        width: 100%;
        max-width: 200px;
      }
    }

    .col {
      position: relative;
      padding: 0.3rem;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      input {
        text-align: center;
      }
    }

    button {
      &.full {
        width: 100%;
        max-width: 200px;
      }
    }

    select {
      max-width: 180px;
      width: 100%;
    }
  }

  .media-wrap {
    flex-grow: 1;
    overflow: hidden;

    .video-window {
      height: 100%;
      &.full {
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .user-img-wrap {
          width: 100%;
          height: 100%;
        }
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    @media (max-width: 500px) {
      position: absolute;
      bottom: 10%;
      padding: 0.5rem;
    }
  }

  .options {
    position: absolute;
    width: 200px;
    height: auto;
    min-height: 200px;
    max-height: 300px;
    z-index: 10;
    background-color: #171d23;
    border: solid 1px #2e4155;
    overflow-y: scroll;
    top: -100px;
    ul {
      li {
        cursor: pointer;
        font-size: 0.9rem;
        padding: 0.5rem;
        border-bottom: solid 1px #2e4155;
        &.active {
          background-color: #22326f;
        }
        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: #2e4155;
        }
      }
    }
  }
}

.mediaSettings {
  max-width: 200px;
}

@media (max-width: 667px) {
  .room,
  .join-room {
    .column {
      &.last {
        &.full {
          width: 230px;
        }

        &.bottom {
          height: auto;

          .chat-wrap {
            position: fixed !important;
            width: 80% !important;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .room,
  .join-room {
    flex-direction: column;
    height: 100%;

    .column {
      &.last {
        &.full {
          width: 100%;
        }

        &.bottom {
          height: auto;

          .chat-wrap {
            position: fixed !important;
            width: 80% !important;
          }
        }
      }
    }
  }
}
