@import "mixins";

.scene {
  font-size: 18px;
  .video-wrap {
    aspect-ratio: 16 / 9;
    border: solid 0.25rem #14202e;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    .video-window {
      aspect-ratio: 16 / 9;
      @include transition(all 0.4s ease-in);
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .level-wrap {
        position: absolute;
        bottom: -5px;
        right: 0;
      }

      .muted-video {
        position: absolute;
        bottom: 0.5rem;
        left: 0.5rem;
      }

      &.host {
        order: -10;
      }

      @import "cameraPositions";

      video {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
        overflow: hidden;
        transition: all 0.3ms ease-in-out;

        &.use-image {
          display: none;
        }

        &.hide {
          display: none;
        }
      }

      .vid-img {
        display: none;
        &.use-image {
          width: 100%;
          height: 100%;
          display: initial;
        }
      }

      img {
        width: 100%;
        transition: all 0.3ms ease-in-out;
        &.hide {
          display: none;
        }
      }

      .name-wrap {
        font-size: 2rem;
        position: absolute;
        top: -10rem;
        left: 0.5rem;
        @include transition(all 0.5s ease-in-out);

        &.on {
          top: 0.2rem;
          position: absolute;
        }

        .display-name {
          display: block;
          padding: 0.3rem 0.5rem 0.3rem;
          background-color: rgba(0, 0, 0, 0.861);
        }
        .tag-line {
          display: block;
          font-size: 80%;
          padding: 0.3rem 0.5rem;
          background-color: rgba(255, 0, 0, 0.861);
        }
      }
    }
  }
}
