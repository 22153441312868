.rundown {
  &.glow {
    width: 400px;
    font-size: 24px;
    position: absolute;
    top: 10%;
    left: -130%;
    padding: 10px;
    border: none;
    border-radius: 8px;
    transition: all 0.6s;

    &.on {
      left: 30px;
    }

    ul {
      li {
        margin: 5px;
        list-style: none;
        padding: 10px 10px;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        background: #6d07ad51;
        border: solid 3px #6d07ad;
        border-radius: 20px;
        margin-bottom: 20px;
        box-shadow: inset 0 0 0.5em 0 #6d07ad, 0 0 0.5em 0 #6d07ad;
        transition: all 0.6s;
        border-radius: 8px;
        width: auto;
        filter: drop-shadow(2px 4px 3px #000000c7);

        &.selected {
          color: white;
          background: #6d07ada3;
        }
      }
    }
  }
}

.scrolling-text-container {
  &.glow {
    width: 100%;
    position: fixed;
    bottom: 0px;
    font-weight: 700;
    text-transform: uppercase;
    background: #6d07ad40;
    box-shadow: inset 0 0 0.5em 0 #6d07ad, 0 0 0.5em 0 #6d07ad;
    color: white;
    border-top: solid 5px #6d07ad;
    text-shadow: 2px 2px 4px #000000;

    .marquee {
      font-size: 1.5em;
    }

    .ticker {
      width: auto;
      overflow: hidden;
      margin: 10px auto 10px auto;
    }
  }
}

.topic-container {
  &.glow {
    display: flex;
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: bottom 0.5s ease-in-out;
    justify-content: center;

    &.on {
      bottom: 60px;
    }

    .topic-source {
      background: #6d07ad40;
      color: white;
      text-shadow: 2px 2px 4px #000000;
      padding: 20px;
      font-size: 48px;
      font-weight: 800;
      max-width: 100%;
      width: 60%;
      border: solid 8px #6d07ad;
      border-radius: 20px;
      margin-bottom: 20px;
      box-shadow: inset 0 0 0.5em 0 #6d07ad, 0 0 0.5em 0 #6d07ad;

      /* Flexbox properties for vertical alignment */
      display: flex;
      justify-content: center; /* Horizontally center content */
      align-items: center; /* Vertically center content */

      span {
        max-width: 80%;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}
